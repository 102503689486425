import { documentService } from '@/views/documents/documents.service'
import type { EStaticDocumentTitle } from '@/views/documents/documents.types'

/**
 * Converts an object to FormData.
 *
 * This function recursively iterates over the properties of the given object
 * and appends them to a FormData instance. If a property is an object itself
 * (and not a File), the function calls itself recursively to handle nested objects.
 *
 * @param obj - The object to be converted to FormData.
 * @param form - An optional FormData instance to append the properties to. If not provided, a new FormData instance is created.
 * @param namespace - An optional namespace to prefix the keys with. Useful for handling nested objects.
 * @returns The FormData instance with the object's properties appended.
 */
export function objectToFormData (obj: Record<string, any>, form?: FormData, namespace?: string): FormData {
  const formData = form || new FormData()

  for (const property in obj) {
    if (obj.hasOwnProperty(property)) {
      const formKey = namespace ? `${namespace}[${property}]` : property

      if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
        objectToFormData(obj[property], formData, formKey)
      } else {
        formData.append(formKey, obj[property])
      }
    }
  }

  return formData
}

export async function getStaticDocumentByTitle (targetDocument: EStaticDocumentTitle) {
  try {
    const documents = await documentService.getStaticDocuments()
    const currentFile = documents.find(document => document.title === targetDocument)?.file
    return currentFile || ''
  } catch (error) {
    console.error('Ошибка при загрузке статичных документов:', error)
    return ''
  }
}
