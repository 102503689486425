<template>
  <div class="fixed bottom-0 right-0 z-40 m-10 max-w-400 rounded-12 bg-white px-24 py-16">
    <div class="flex flex-col items-start justify-between">
      <div>
        <h1 class="mb-4 font-sfpd text-20 font-medium text-primary">
          Использование cookies
        </h1>
        <p class="mb-16 text-14 leading-normal text-primary">
          Мы используем cookies для улучшения сайта.<br>
          Оставаясь на сайте, вы подтверждаете согласие с этой технологией и
          <a :href="privacyLink" target="_blank" class="text-14 !text-azure underline">
            Политикой Конфиденциальности
          </a>
        </p>
        <el-button
          class="w-full whitespace-break-spaces px-32 py-24 text-14 md:text-18"
          type="primary"
          @click="createCookie()"
        >
          Хорошо
        </el-button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { getStaticDocumentByTitle } from '@/core/helpers/shared'
import { EStaticDocumentTitle } from '@/views/documents/documents.types'

const emit = defineEmits(['cookieAccepted'])

function createCookie () {
  document.cookie = 'is_cookie_accepted=true; domain=.biglaw.ru; max-age=31536000; path=/'
  emit('cookieAccepted')
}

const privacyLink = ref('')

watchEffect(async () => {
  privacyLink.value = await getStaticDocumentByTitle(EStaticDocumentTitle.PRIVACY)
})
</script>
