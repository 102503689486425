export interface IDocumentTemplate {
  id: number
  type: string
  template: string
  instructions: string
}

export interface IDocument {
  document_template: IDocumentTemplate
  document_title: string
  document_preview_title: string
  document_file: string | null
  document_id: number | null
  document_pages_count: number | null
}

export interface IDocumentUpload {
  template: number
  title: string
  file: File
}

export enum EStaticDocumentTitle {
  ATTORNEY = 'Доверенность Главное Право',
  PRIVACY = 'Политика Главное Право',
  OFERTA = 'Оферта Главное Право'
}

export interface IStaticDocument {
  id: number
  title: EStaticDocumentTitle | string
  file: string
}

export interface IDocumentUploaded {
  id: number
  title: string
  template: number
  file: string
}
