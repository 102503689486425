<template>
  <main>
    <ElConfigProvider :size="$componentSize.DEFAULT" :locale>
      <router-view />
      <CookieNotification v-if="!is_cookie_agree" @cookieAccepted="updateCookieAgree" />
    </ElConfigProvider>
  </main>
</template>

<script setup lang="ts">
import ru from 'element-plus/es/locale/lang/ru'
import { checkCookieAgree } from './core/utils/shared/cookie-cheker'
const locale = ru

const is_cookie_agree = ref(false)

function updateCookieAgree () {
  is_cookie_agree.value = checkCookieAgree()
}

onMounted(() => {
  updateCookieAgree()
})
</script>
