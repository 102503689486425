import { objectToFormData } from '@/core/helpers/shared'
import type { IDocument, IDocumentUpload, IDocumentUploaded, IStaticDocument } from './documents.types'
import { http } from '@/http'

class DocumentService {
  async getDocuments () {
    return http.get<IDocument[]>('/document/list/')
  }

  async uploadDocument (_payload: IDocumentUpload) {
    return http.post<IDocumentUploaded>('/document/upload/', objectToFormData(_payload))
  }

  async deleteDocument (_id: number) {
    return http.delete<void>(`/document/${_id}/delete/`)
  }

  async sendDocuments () {
    return http.post('/document/process/')
  }

  async getStaticDocuments () {
    return http.get<IStaticDocument[]>('/static-documents/')
  }
}

export const documentService = new DocumentService()
