import { http } from '@/http'
import {
  EDadataType,
  EDadataTypeExtended,
  type IDadataAddress,
  type IDadataAddressRegion,
  type IDadataBank, type IDadataOrganization,
  type IFormPayload
} from '@/types/shared'
import { isEmptyObj, uuid } from '@/core/utils/shared'
import { getTriggers } from './helpers'

class SurveyService {
  updateForm (_payload: IFormPayload, _updateTriggers = false, _formForTriggers: IFormPayload) {
    const payload = _updateTriggers
      ? { ..._payload, triggers: getTriggers(_formForTriggers) }
      : _payload

    return http.post('/form/', payload)
  }

  fakeFormFields (_payload: Required<Pick<IFormPayload, 'step1' | 'step2' | 'step3' | 'step4' | 'step5' | 'step6' | 'step7'>>) {
    return http.post<{300: number; 301: number; 302: number}>('/form/fake/', _payload)
  }

  async getFormDetail () {
    const result: IFormPayload = {}
    const response = await http.get<IFormPayload>('/form/detail/')
    Object.entries(response).forEach(([key, value]) => {
      if (isEmptyObj(value)) return
      result[key as keyof IFormPayload] = value
    })
    return result
  }

  async getDadataInfo <T extends EDadataType | EDadataTypeExtended> (query: string, type: T, locationFilter = '') {
    if (query === '') return []

    switch (type) {
    case EDadataType.ADDRESS:
      return (await http.post<Partial<IDadataAddress>[]>('/dadata/v2/', { text: query, type }))
        .map((item) => ({ ...item, id: uuid() }))
    case EDadataTypeExtended.ADDRESS_REGION:
      return (await http.post<IDadataAddressRegion[]>('/dadata/v2/', { text: query, type: 1, location_filter: locationFilter }))
        .map((item) => ({ ...item, id: uuid(), value: item.region }))
    case EDadataType.BANK:
      return (await http.post<IDadataBank[]>('/dadata/v2/', { text: query, type }))
        .map((item) => ({
          ...item,
          value: `${item.value} (${item.inn}), ${item.address}`,
          name: `${item.value} (${item.inn})`,
          id: uuid()
        }))
    case EDadataType.ORGANIZATION:
      return (await http.post<IDadataOrganization[]>('/dadata/v2/', { text: query, type }))
        .map((item) => ({ ...item, id: uuid() }))
    case EDadataTypeExtended.ORGANIZATION_INN_ADDRESS:
      return (await http.post<IDadataOrganization[]>('/dadata/v2/', { text: query, type: EDadataType.ORGANIZATION }))
        .map((item) => ({
          ...item,
          value: `${item.value} (${item.inn}), ${item.address}`,
          name: `${item.value} (${item.inn})`,
          id: uuid()
        }))
    case EDadataTypeExtended.ORGANIZATION_ADDRESS:
      return (await http.post<IDadataOrganization[]>('/dadata/v2/', { text: query, type: EDadataType.ORGANIZATION }))
        .map((item) => ({
          ...item,
          value: `${item.value}, ${item.address}`,
          name: item.value,
          id: uuid()
        }))
    default:
      return []
    }
  }

  getTriggerByCode (_code: number) {
    return http.get<{id: number; code: number; text: string}>(`/trigger/${_code}/`)
  }
}
export const surveyService = new SurveyService()
